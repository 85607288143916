import { useCallback, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

import { type Organization } from '../../../types';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { useLogoBrandColor } from '../../VenueOrgLogoAverageColor/useLogoBrandColor';

function BrandColorPickerModal(props: {
  value: string;
  onChange: (color: string) => void;
  onClose: () => void;
  onSave: () => void;
  organization?: Organization;
}): JSX.Element {
  const { value, onChange, onClose, onSave, organization } = props;

  const { data: logoColorFormatter } = useLogoBrandColor(organization);
  const logoColor = logoColorFormatter?.cssHex();

  const handleUseLogoColor = () => {
    if (logoColor) {
      onChange(logoColor);
    }
  };

  return (
    <ModalWrapper
      borderStyle='gray'
      onClose={onClose}
      containerClassName='w-120'
    >
      <div className='px-5 py-8 text-white flex flex-col justify-center'>
        <header className='w-full text-2xl text-center font-medium mb-8'>
          Choose Brand Color
        </header>

        <div className='space-y-6'>
          <div
            className='h-12 w-full rounded border border-gray-700'
            style={{ backgroundColor: value }}
          />

          <div className='flex justify-center'>
            <HexColorPicker
              color={value}
              onChange={onChange}
              className='!w-[280px]'
            />
          </div>

          <div className='space-y-2'>
            <label className='font-bold text-base'>
              Hex Color
              <div className='flex items-center space-x-2 mt-1'>
                <span className='text-gray-400'>#</span>
                <HexColorInput
                  color={value}
                  onChange={onChange}
                  prefixed={false}
                  className='field flex-1'
                />
              </div>
            </label>
          </div>

          {logoColor && (
            <div className='flex justify-center'>
              <button
                type='button'
                onClick={handleUseLogoColor}
                className='btn-primary w-40 h-10'
              >
                Use Logo Color
              </button>
            </div>
          )}

          <div className='flex justify-center mt-6 gap-4'>
            <button
              type='button'
              onClick={onClose}
              className='btn-secondary w-40 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={onSave}
              className='btn-primary w-40 h-10'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export interface TriggerColorPickerModalProps {
  initialColor: string;
  onConfirm: (color: string) => void;
  organization: Organization;
}

export function useTriggerColorPickerModal() {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerColorPickerModalProps) => {
      return triggerConfirmCancelModal({
        kind: 'custom',
        element: (p) => {
          function ModalContent() {
            const [currentColor, setCurrentColor] = useState(
              props.initialColor
            );

            return (
              <BrandColorPickerModal
                value={currentColor}
                onChange={setCurrentColor}
                onClose={p.internalOnCancel}
                onSave={() => {
                  p.internalOnConfirm();
                  props.onConfirm(currentColor);
                }}
                organization={props.organization}
              />
            );
          }

          return <ModalContent />;
        },
      });
    },
    [triggerConfirmCancelModal]
  );
}
