import { useSearchParams } from '@remix-run/react';
import { useMemo, useRef, useState } from 'react';

import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { type SessionTrackSearchParams } from '../../../services/api-service';
import { type Organizer, OrganizerRoleUtils, RoleUtils } from '../../../types';
import { assertExhaustive } from '../../../utils/common';
import { uncheckedIndexAccess_UNSAFE } from '../../../utils/uncheckedIndexAccess_UNSAFE';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons/Arrows';
import { ScoreboardIcon } from '../../icons/ScoreboardIcon';
import { SessionTrackHistory } from '../../Session';
import { useUser } from '../../UserContext';
import { useOrgContext } from './Context';

type Filter = {
  kind: 'venue' | 'org';
  id: string;
};

const FILTER_LABEL_MAP: { [key in Filter['kind']]: string } = {
  venue: 'My Venue',
  org: 'All Venues',
};

function FilterUtils(props: {
  organizer: Organizer;
  updateFilter: (next: Filter) => void;
}): JSX.Element | null {
  const { org } = useOrgContext();
  const { organizer, updateFilter } = props;
  const [filter, setFilter] = useState<Filter>({
    kind: 'venue',
    id: organizer.venueId,
  });
  const [showFilters, setShowFilters] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleUpdateFilter = (next: Filter) => {
    setFilter(next);
    updateFilter(next);
    setShowFilters(false);
  };

  useOutsideClick(ref, () => setShowFilters(false));

  return (
    <div ref={ref} className='relative'>
      <button
        className='w-40 h-12.5 rounded-xl btn btn-secondary font-medium flex items-center justify-center text-white'
        onClick={() => setShowFilters(!showFilters)}
      >
        <p className='mr-2'>{FILTER_LABEL_MAP[filter.kind]}</p>
        {showFilters ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </button>
      {showFilters && (
        <div className='w-full bg-black border border-secondary rounded-xl text-3xs absolute mt-0.5'>
          <div
            className='w-full hover:bg-secondary pl-7 pr-4 py-4 rounded-xl cursor-pointer'
            onClick={() =>
              handleUpdateFilter({ kind: 'venue', id: organizer.venueId })
            }
          >
            {FILTER_LABEL_MAP['venue']}
          </div>
          <div
            className='w-full hover:bg-secondary pl-7 pr-4 py-4 rounded-xl cursor-pointer'
            onClick={() => handleUpdateFilter({ kind: 'org', id: org.id })}
          >
            {FILTER_LABEL_MAP['org']}
          </div>
        </div>
      )}
    </div>
  );
}

const clearableFilterParams = ['eventOrganizerId'];

export function OrganizationDetailsSessions(props: {
  header?: boolean;
  className?: string;
}): JSX.Element | null {
  const { org } = useOrgContext();
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const orgManageable =
    user.organizer?.orgId === org.id &&
    OrganizerRoleUtils.isOwnerOrAdmin(user.organizer);
  const orgId = isAdmin && !orgManageable ? org.id : null;
  const [params, setParams] = useSearchParams();
  const [searchParams, setSearchParams] = useState<SessionTrackSearchParams>({
    venueId: !orgId ? user.organizer?.venueId : null,
    orgId: orgId,
    eventOrganizerId:
      isAdmin && params.has('eventOrganizerId')
        ? params.get('eventOrganizerId')
        : null,
  });
  const config = useMemo(() => {
    return {
      organizer: !searchParams.venueId,
      mode: isAdmin,
    };
  }, [searchParams.venueId, isAdmin]);
  const hasClearableFilters = useMemo(() => {
    return clearableFilterParams.some((param) =>
      Boolean(uncheckedIndexAccess_UNSAFE(searchParams)[param])
    );
  }, [searchParams]);

  const updateFilter = (next: Filter) => {
    switch (next.kind) {
      case 'venue':
        setSearchParams({ ...searchParams, venueId: next.id, orgId: null });
        break;
      case 'org':
        setSearchParams({ ...searchParams, orgId: next.id, venueId: null });
        break;
      default:
        assertExhaustive(next.kind);
        break;
    }
  };

  const clearFilters = () => {
    const nextQueryParams = new URLSearchParams(params);
    const nextSearchParams = { ...searchParams };
    for (const param of clearableFilterParams) {
      delete uncheckedIndexAccess_UNSAFE(nextQueryParams)[param];
      delete uncheckedIndexAccess_UNSAFE(nextSearchParams)[param];
    }
    setParams(nextQueryParams);
    setSearchParams(nextSearchParams);
  };

  return (
    <div className={`w-full h-full text-white ${props.className ?? ''}`}>
      <div className='w-full flex justify-between items-start'>
        <div className='flex items-center'>
          <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
          <div className='ml-2 font-medium text-2xl'>
            Analytics{' '}
            {props.header &&
              ` - ${searchParams.orgId ? 'All Venues' : 'My Venue'}`}
          </div>
        </div>
        {user.organizer && (
          <FilterUtils organizer={user.organizer} updateFilter={updateFilter} />
        )}
        {isAdmin && hasClearableFilters && (
          <button
            type='button'
            className='w-40 h-12.5 rounded-xl btn btn-secondary font-medium flex items-center justify-center text-white'
            onClick={clearFilters}
          >
            Clear Filters
          </button>
        )}
      </div>
      <main className='w-full mt-10 scrollbar'>
        <SessionTrackHistory searchParams={searchParams} config={config} />
      </main>
    </div>
  );
}
