import { Link } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';

import {
  type DtoOrgSubscription,
  type DtoToggleOrgGameUpdatesRequest,
  type DtoUpdateOrgSettingsRequest,
  EnumsOrgSubscriptionPlan,
  EnumsOrgSubscriptionStatus,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import {
  type Organization,
  type OrgConnection,
  OrgSubscriptionUtils,
} from '../../../types/organization';
import { err2s } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { SettingIcon } from '../../icons/SettingIcon';
import { Loading } from '../../Loading';
import { MiniMediaUploader } from '../../MediaUploader/MiniMediaUploader';
import { PaymentUtils } from '../../Payment/utils';
import { SwitcherControlled } from '../../Switcher';
import { useUser, useUserContext } from '../../UserContext';
import { useVenueSlug } from '../../Venue/VenueProvider';
import { useOrganizationSubscription } from '../hooks';
import { useOrgConnection } from '../hooks/useOrgConnection';
import {
  useTriggerDeleteOrganizationModal,
  useTriggerSlackAsSourceOfTruthModal,
  useTriggerSlackNotAsSourceOfTruthModal,
} from '../Modals/';
import { useTriggerColorPickerModal } from '../Modals/BrandColorPicker';
import {
  formatOrgSizeLabel,
  OrganizationMaxSizeSelect,
} from '../OrganizationSize';
import {
  OrgSubscriptionProductSelect,
  OrgSubscriptionStatusSelect,
} from '../OrgSubscription';
import { OrgUtils } from '../utils';
import { useOrgContext } from './Context';

function HostForOrgButton(props: { org: Organization }): JSX.Element {
  const venueSlug = useVenueSlug();
  const searchParam = new URLSearchParams({
    'org-id': props.org.id,
    organization: `${props.org.name}_${new Date()
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      })
      .split('/')
      .join('')}`,
  }).toString();

  return (
    <Link
      className='px-6.5 py-3 btn-primary font-medium text-base text-white'
      to={`/host/venue/${venueSlug}?${searchParam}`}
      reloadDocument
    >
      Host for Org
    </Link>
  );
}

type FieldContainerProps<T> = {
  title: string;
  subtitle?: string | React.ReactNode;
  value: T;
} & (
  | {
      editing: false;
      children: React.ReactNode;
      onEdit: () => void;
    }
  | {
      editing: true;
      children: (props: {
        value: T;
        onChange: (updated: T) => void;
      }) => React.ReactNode;
      onCancel: () => void;
      onSave: (val: T) => Promise<void>;
    }
);

function FieldContainer<T>(props: FieldContainerProps<T>): JSX.Element {
  const [editingVal, setEditingVal] = useState(props.value);

  const handleCancel = () => {
    if (!props.editing) return;
    props.onCancel();
  };

  const {
    call: handleSave,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    if (!props.editing) return;
    if (editingVal !== props.value) {
      await props.onSave(editingVal);
    }
  });

  const handleEdit = () => {
    if (props.editing) return;
    setEditingVal(props.value);
    props.onEdit();
  };

  return (
    <div>
      <div className='text-base font-bold'>{props.title}</div>
      {props.subtitle && (
        <div className='mt-1 text-xs text-icon-gray'>{props.subtitle}</div>
      )}
      <div className='mt-2 flex items-center text-sms gap-4'>
        <div className='w-75 h-10 flex items-center'>
          {!props.editing
            ? props.children
            : props.children({
                value: editingVal,
                onChange: (v) => setEditingVal(v),
              })}
        </div>
        {props.editing ? (
          <div className='flex items-center gap-2'>
            <button
              type='button'
              onClick={() => handleCancel()}
              className='btn-secondary w-20 h-10'
            >
              Cancel
            </button>
            <button
              type='button'
              onClick={() => handleSave()}
              className='btn-primary w-20 h-10'
              disabled={isRunning}
            >
              {isRunning ? 'Saving...' : 'Save'}
            </button>
          </div>
        ) : (
          <button
            type='button'
            onClick={() => handleEdit()}
            className='btn text-primary'
          >
            Edit
          </button>
        )}
      </div>
      {error && (
        <div className='mt-1 px-2 text-sms text-red-005'>{err2s(error)}</div>
      )}
    </div>
  );
}

type FieldProps = {
  organization: Organization;
  onChange: (organization: Organization) => void;
  editing: boolean;
  onEdit: () => void;
  onCancelEdit: () => void;
};

function DisplayNameField(props: FieldProps) {
  const title = 'Organization Display Name';
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.name}
        editing={false}
        onEdit={props.onEdit}
      >
        {props.organization.name}
      </FieldContainer>
    );

  const handleSave = async (val: string) => {
    if (val.length === 0 || val.length > 50)
      throw new Error('Must be 1 to 50 characters');

    const resp = await apiService.organization.updateOrganization(
      props.organization.id,
      {
        name: val,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.name}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className='w-full h-full field mb-0'
          autoFocus
        />
      )}
    </FieldContainer>
  );
}

function BrandColorField(props: FieldProps) {
  const title = 'Organization Brand Color';
  const triggerColorPickerModal = useTriggerColorPickerModal();

  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.settings?.brandColor || ''}
        editing={false}
        onEdit={props.onEdit}
      >
        <div className='flex items-center gap-3'>
          {props.organization.settings?.brandColor ? (
            <>
              <div
                className='w-6 h-6 rounded border border-gray-700'
                style={{
                  backgroundColor: props.organization.settings.brandColor,
                }}
              />
              {props.organization.settings.brandColor}
            </>
          ) : (
            'None'
          )}
        </div>
      </FieldContainer>
    );

  const handleSave = async (val: string) => {
    if (val.length === 0 || val.length > 50)
      throw new Error('Must be 1 to 50 characters');

    const resp = await apiService.organization.updateOrgSettings(
      props.organization.id,
      {
        brandColor: val,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.settings?.brandColor || '#000000'}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <div className='flex items-center gap-3'>
          <button
            type='button'
            onClick={() => {
              triggerColorPickerModal({
                initialColor: value,
                onConfirm: onChange,
                organization: props.organization,
              });
            }}
            className='flex items-center gap-2 px-4 py-2 bg-gray-800 rounded hover:bg-gray-700 transition-colors'
          >
            <div
              className='w-4 h-4 rounded border border-gray-700'
              style={{ backgroundColor: value }}
            />
            <span>{value}</span>
          </button>
        </div>
      )}
    </FieldContainer>
  );
}

function SeatOverageNotificationField(props: {
  organization: Organization;
  onChange: (organization: Organization) => void;
}) {
  const { organization, onChange } = props;

  const {
    call: handleChange,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async (enabled: boolean) => {
    const resp = await apiService.organization.updateOrgSettings(
      organization.id,
      {
        seatOverageNotificationEnabled: enabled,
      }
    );
    onChange(resp.data.organization);
  });

  return (
    <div className='flex items-center'>
      <p className='text-sms w-75'>Send messages when over limit</p>
      <SwitcherControlled
        name='org-seat-overage-notification-toggle'
        className=''
        checked={!!organization.settings?.seatOverageNotificationEnabled}
        onChange={(val) => handleChange(val)}
        disabled={isRunning}
      />
    </div>
  );
}

function SizeField(props: FieldProps) {
  const title = 'Org Size';
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.maxSize}
        editing={false}
        onEdit={props.onEdit}
      >
        {formatOrgSizeLabel(props.organization.maxSize)}
      </FieldContainer>
    );

  const handleSave = async (val: number | null) => {
    const resp = await apiService.organization.updateOrganization(
      props.organization.id,
      {
        maxSize: val,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      subtitle='Select N/A for an unbounded organization size. Type a number to define a custom size.'
      value={props.organization.maxSize}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <OrganizationMaxSizeSelect
          value={value}
          onChange={onChange}
          className='w-full h-full'
        />
      )}
    </FieldContainer>
  );
}

function SubscriptionProductField(props: FieldProps) {
  const title = 'Subscription Product';

  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.subscription}
        editing={false}
        onEdit={props.onEdit}
      >
        {`${OrgSubscriptionUtils.GetSubscriptionPlanName(
          props.organization
        )} (${
          props.organization.subscription.plan ===
          EnumsOrgSubscriptionPlan.OrgSubscriptionPlanFree
            ? 'Free'
            : 'Paid'
        })`}
      </FieldContainer>
    );

  const handleSave = async (val: DtoOrgSubscription) => {
    if (!val.productId) return;
    await apiService.organization.updateSubscriptionPlan(
      props.organization.id,
      { productId: val.productId, priceId: val.priceId }
    );
    const resp = await apiService.organization.getOrganization(
      props.organization.id
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.subscription}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <OrgSubscriptionProductSelect
          value={value}
          onChange={({ productId, priceId }) =>
            onChange({ ...value, productId, priceId })
          }
        />
      )}
    </FieldContainer>
  );
}

function SubscriptionStatusField(props: FieldProps) {
  const title = 'Subscription Status';
  const { data } = useOrganizationSubscription(props.organization.id);

  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        subtitle={
          data?.status ===
            EnumsOrgSubscriptionStatus.OrgSubscriptionStatusTrialing &&
          data?.trialEnd && (
            <>
              Trial ends on{' '}
              <strong>{format(new Date(data.trialEnd), 'MMMM d, yyyy')}</strong>
            </>
          )
        }
        value={props.organization.subscription.status}
        editing={false}
        onEdit={props.onEdit}
      >
        {capitalize(props.organization.subscription.status ?? 'active')}
      </FieldContainer>
    );

  const handleSave = async (status: EnumsOrgSubscriptionStatus) => {
    await apiService.organization.updateSubscriptionStatus(
      props.organization.id,
      status
    );
    const resp = await apiService.organization.getOrganization(
      props.organization.id
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      subtitle={
        <p>Canceled organizations are opted out of messaging campaigns.</p>
      }
      value={
        props.organization.subscription.status ??
        EnumsOrgSubscriptionStatus.OrgSubscriptionStatusActive
      }
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <OrgSubscriptionStatusSelect
          value={value}
          onChange={onChange}
          className='w-full h-full'
        />
      )}
    </FieldContainer>
  );
}

function SubscriptionIdField(props: FieldProps) {
  const title = 'Stripe Subscription Id';
  const { data } = useOrganizationSubscription(props.organization.id);

  let subtitle = <></>;
  if (data?.cancelAt) {
    subtitle = (
      <>Cancels on {format(new Date(data.cancelAt), 'MMMM d, yyyy')}</>
    );
  } else if (data?.discount?.coupon) {
    subtitle = (
      <>
        {PaymentUtils.FormatCouponOff(data.discount.coupon)}{' '}
        {data.discount.endAt && (
          <>
            until{' '}
            <strong>
              {format(new Date(data.discount.endAt), 'MMMM d, yyyy')}
            </strong>
          </>
        )}
      </>
    );
  }

  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        subtitle={subtitle}
        value={props.organization.subscription.subscriptionId}
        editing={false}
        onEdit={props.onEdit}
      >
        {props.organization.subscription.subscriptionId || 'N/A'}
      </FieldContainer>
    );

  const handleSave = async (subscriptionId: string) => {
    await apiService.organization.updateStripeSubscription(
      props.organization.id,
      {
        subscriptionId,
      }
    );
    const resp = await apiService.organization.getOrganization(
      props.organization.id
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      subtitle={
        <p>
          Set this field to associate the organization with a Stripe
          subscription.
        </p>
      }
      value={props.organization.subscription.subscriptionId ?? ''}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className='w-full h-full field mb-0'
          autoFocus
        />
      )}
    </FieldContainer>
  );
}

function CanTrialField(props: FieldProps) {
  const title = 'Can Trial';

  const styles = useMemo(() => buildReactSelectStyles<Option<boolean>>(), []);
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.canTrial}
        editing={false}
        onEdit={props.onEdit}
      >
        {props.organization.canTrial ? 'Yes' : 'No'}
      </FieldContainer>
    );

  const handleSave = async (canTrial: boolean) => {
    await apiService.organization.updateOrganizationCanTrial(
      props.organization.id,
      canTrial
    );
    const resp = await apiService.organization.getOrganization(
      props.organization.id
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.canTrial}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <Select<Option<boolean>, false>
          className='w-30'
          classNamePrefix='select-box-v2'
          styles={styles}
          value={{
            label: value ? 'Yes' : 'No',
            value,
          }}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          onChange={(v) => onChange(v?.value ?? false)}
          isSearchable={false}
          isMulti={false}
        />
      )}
    </FieldContainer>
  );
}

function OnboardingCallScheduledField(props: FieldProps) {
  const title = 'Onboarding Call Scheduled';

  const styles = useMemo(() => buildReactSelectStyles<Option<boolean>>(), []);
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.settings?.onboardingCallScheduled}
        editing={false}
        onEdit={props.onEdit}
      >
        {props.organization.settings?.onboardingCallScheduled ? 'Yes' : 'No'}
      </FieldContainer>
    );

  const handleSave = async (value: boolean) => {
    const resp = await apiService.organization.updateOrgSettings(
      props.organization.id,
      {
        onboardingCallScheduled: value,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.settings?.onboardingCallScheduled || false}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <Select<Option<boolean>, false>
          className='w-30'
          classNamePrefix='select-box-v2'
          styles={styles}
          value={{
            label: value ? 'Yes' : 'No',
            value,
          }}
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          onChange={(v) => onChange(v?.value ?? false)}
          isSearchable={false}
          isMulti={false}
        />
      )}
    </FieldContainer>
  );
}

function CancelOfferField(props: FieldProps) {
  const title = 'Can Take Cancel Offer';

  const styles = useMemo(() => buildReactSelectStyles<Option<boolean>>(), []);
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.settings?.cancelOfferDisabled}
        editing={false}
        onEdit={props.onEdit}
      >
        {!props.organization.settings?.cancelOfferDisabled ? 'Yes' : 'No'}
      </FieldContainer>
    );

  const handleSave = async (value: boolean) => {
    const resp = await apiService.organization.updateOrgSettings(
      props.organization.id,
      {
        cancelOfferDisabled: value,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={props.organization.settings?.cancelOfferDisabled ?? false}
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <Select<Option<boolean>, false>
          className='w-30'
          classNamePrefix='select-box-v2'
          styles={styles}
          value={{
            label: value ? 'No' : 'Yes',
            value,
          }}
          options={[
            {
              label: 'No',
              value: true,
            },
            {
              label: 'Yes',
              value: false,
            },
          ]}
          onChange={(v) => onChange(v?.value ?? false)}
          isSearchable={false}
          isMulti={false}
        />
      )}
    </FieldContainer>
  );
}

function VenueConnectivityTestField(props: FieldProps) {
  const title = 'In-Venue Connectivity Test';

  const styles = useMemo(() => buildReactSelectStyles<Option<boolean>>(), []);
  if (!props.editing)
    return (
      <FieldContainer
        title={title}
        value={props.organization.settings?.venueConnectivityTestDisabled}
        editing={false}
        onEdit={props.onEdit}
      >
        {!props.organization.settings?.venueConnectivityTestDisabled
          ? 'Enabled'
          : 'Disabled'}
      </FieldContainer>
    );

  const handleSave = async (value: boolean) => {
    const resp = await apiService.organization.updateOrgSettings(
      props.organization.id,
      {
        venueConnectivityTestDisabled: value,
      }
    );
    props.onChange(resp.data.organization);
  };

  return (
    <FieldContainer
      title={title}
      value={
        props.organization.settings?.venueConnectivityTestDisabled || false
      }
      editing
      onCancel={props.onCancelEdit}
      onSave={handleSave}
    >
      {({ value, onChange }) => (
        <Select<Option<boolean>, false>
          className='w-30'
          classNamePrefix='select-box-v2'
          styles={styles}
          value={{
            label: !value ? 'Enabled' : 'Disabled',
            value,
          }}
          options={[
            {
              label: 'Enabled',
              value: false,
            },
            {
              label: 'Disabled',
              value: true,
            },
          ]}
          onChange={(v) => onChange(v?.value ?? false)}
          isSearchable={false}
          isMulti={false}
        />
      )}
    </FieldContainer>
  );
}

function LogoEditor(props: {
  organization: Organization;
  connection?: OrgConnection | null;
  onChange: (updated: Organization) => void;
}): JSX.Element {
  const { organization, connection, onChange } = props;
  const [importing, setImporting] = useState(false);

  const withSlackImport = connection?.status === 'active' && !!connection.icon;

  const handleLogoChange = useCallback(
    async (media: Media | null) => {
      const resp = await apiService.organization.updateOrganizationLogo(
        organization.id,
        {
          logoId: media === null ? null : media.id,
        }
      );
      if (resp) {
        onChange(resp.data.organization);
      }
    },
    [organization.id, onChange]
  );

  const handleImport = useCallback(async () => {
    setImporting(true);
    try {
      const resp = await apiService.organization.importOrganizationLogo(
        organization.id
      );
      if (resp) {
        onChange(resp.data.organization);
      }
    } finally {
      setImporting(false);
    }
  }, [organization.id, onChange]);

  return (
    <div>
      <div className='text-white font-bold mb-2.5'>Logo</div>
      <div className='flex items-start gap-4'>
        <div className='relative flex flex-col gap-2 w-20 h-20'>
          <MiniMediaUploader
            uploaderId={`logo-updater-${organization.id}`}
            onUploadSuccess={handleLogoChange}
            onDelete={() => handleLogoChange(null)}
            media={organization.logo}
            video={false}
            customSizeStyle='w-full h-full'
            customPreview={(mediaUrl) => (
              <img
                className='w-full h-full object-cover'
                src={mediaUrl}
                alt='Organization Logo'
              />
            )}
            noPlayIcon
          />
          {importing && (
            <div className='absolute inset-0 bg-lp-black-001 flex items-center justify-center'>
              <Loading text='' />
            </div>
          )}
        </div>
        <div className='text-icon-gray text-xs w-100'>
          Some tips for your logo
          <ul className='list-inside list-disc'>
            <li>Upload an image that is a square and is 132px or larger.</li>
            <li>
              Use a graphical logo or image rather than text. (We’ll add your
              org name next to the logo you upload)
            </li>
            <li>Leave some space around your icon.</li>
          </ul>
        </div>
      </div>

      <div className='text-xs text-secondary'>
        {withSlackImport ? (
          <button
            type='button'
            className='underline disabled:opacity-50'
            onClick={handleImport}
            disabled={importing}
          >
            or import from slack
          </button>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </div>
  );
}

function AsSourceOfTruthToggle(props: {
  connection: OrgConnection;
  onChange: () => void;
}): JSX.Element | null {
  const triggerSlackAsSourceOfTruthModal =
    useTriggerSlackAsSourceOfTruthModal();
  const triggerSlackNotAsSourceOfTruthModal =
    useTriggerSlackNotAsSourceOfTruthModal();

  const handleChange = async (checked: boolean) => {
    if (checked) {
      triggerSlackAsSourceOfTruthModal({
        connection: props.connection,
        onConfirm: props.onChange,
      });
    } else {
      triggerSlackNotAsSourceOfTruthModal({
        connection: props.connection,
        onConfirm: props.onChange,
      });
    }
  };

  return (
    <div className='w-60 h-10 border border-tertiary rounded-lg p-2.5 flex items-center justify-between'>
      <div className='text-sms font-normal'>⚠️ Slack as source of truth</div>
      <SwitcherControlled
        name='org-connection-as-source-of-truth'
        className=''
        checked={props.connection.asSourceOfTruth}
        onChange={handleChange}
      />
    </div>
  );
}

function UsesSlackToggle(props: {
  organization: Organization;
  connection: OrgConnection | null;
  onChange: (value: boolean) => void;
}) {
  const { organization, connection, onChange } = props;

  const handleChange = async (checked: boolean) => {
    await apiService.organization.updateOrganizationUsesSlack(
      organization.id,
      checked
    );
    onChange(checked);
  };

  const disabled = connection?.type === 'slack' && organization.usesSlack;
  return (
    <div className='w-60 h-10 px-2.5 flex items-center justify-between'>
      <div className='text-sms font-normal'>Organization Uses Slack</div>
      <SwitcherControlled
        name='org-uses-slack'
        className=''
        checked={organization.usesSlack}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}

function DeleteButton(props: {
  organization: Organization;
  onDelete: () => void;
}): JSX.Element {
  const triggerDeleteOrganizationModal = useTriggerDeleteOrganizationModal();

  const handleDelete = () => {
    triggerDeleteOrganizationModal({
      organization: props.organization,
      onDelete: props.onDelete,
    });
  };

  return (
    <button
      type='button'
      onClick={handleDelete}
      className='btn px-2.5 text-red-005 text-sms font-medium'
    >
      Delete Organization
    </button>
  );
}

function PausePromotionsToggle(props: {
  org: Organization;
  onChange: (updated: Organization) => void;
}): JSX.Element | null {
  const { org } = props;

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    call,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (req: DtoToggleOrgGameUpdatesRequest) => {
    await apiService.promotion.toggleOrgGameUpdates(req);

    const resp = await apiService.organization.getOrganization(props.org.id);
    props.onChange(resp.data.organization);
  });

  const handleChange = async (val: boolean, medium: 'email' | 'slack') => {
    const response = await triggerModal({
      kind: 'confirm-cancel',
      boxDimensionsClassName: 'w-100 p-4',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>
            {val ? 'Pause Promotions' : 'Resume Promotions'}
          </ConfirmCancelModalHeading>
          <div className='mt-4 text-sms font-normal text-left text-white'>
            {val ? (
              <div className='text-left'>
                When this toggle is turned on:
                <ul className=' list-disc list-inside '>
                  <li>
                    {capitalize(medium)} game updates (promotions) will be
                    turned off for the entire organization.
                  </li>
                  <li>
                    This change will be reflected in each individual user’s
                    notification settings.
                  </li>
                  <li>
                    users can still choose to re-enable notifications in their
                    own settings if they wish.
                  </li>
                </ul>
              </div>
            ) : (
              <div className='text-left'>
                When this toggle is turned off:
                <ul className=' list-disc list-inside '>
                  <li>
                    {capitalize(medium)} game updates (promotions) will be
                    turned on for the entire organization.
                  </li>
                  <li>
                    This change will be reflected in each individual user’s
                    notification settings.
                  </li>
                  <li>
                    Users can still choose to disable notifications in their own
                    settings if they wish.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      ),
      confirmBtnLabel: 'Confirm',
      confirmBtnVariant: 'delete',
    });

    if (response.result !== 'confirmed') return;

    switch (medium) {
      case 'slack':
        await call({
          orgId: org.id,
          pauseGameUpdatesSlack: val,
        });
        break;
      case 'email':
        await call({
          orgId: org.id,
          pauseGameUpdatesEmail: val,
        });
        break;
    }
  };

  const handlePauseDEI = async (val: boolean) => {
    await call({
      orgId: org.id,
      pauseDEI: val,
    });
  };

  return (
    <div className='text-white'>
      <div className='text-base font-bold'>Pause Promotions</div>
      {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
      <div className='mt-2 flex flex-col gap-2'>
        <div className='w-100 flex items-center justify-between'>
          <p className='text-sms'>Pause Slack promotions</p>
          <SwitcherControlled
            name='org-pause-promotions-slack-toggle'
            className=''
            checked={!!org.settings?.promotion.pauseGameUpdateSlack}
            onChange={(val) => handleChange(val, 'slack')}
            disabled={isRunning}
          />
        </div>
        <div className='w-100 flex items-center justify-between'>
          <p className='text-sms'>Pause email promotions</p>
          <SwitcherControlled
            name='org-pause-promotions-email-toggle'
            className=''
            checked={!!org.settings?.promotion.pauseGameUpdatesEmail}
            onChange={(val) => handleChange(val, 'email')}
            disabled={isRunning}
          />
        </div>
        <div className='w-100 flex items-center justify-between'>
          <p className='text-sms'>Pause DEI promotions (email and slack)</p>
          <SwitcherControlled
            name='org-pause-promotions-dei-toggle'
            className=''
            checked={!!org.settings?.promotion.pauseDEI}
            onChange={handlePauseDEI}
            disabled={isRunning}
          />
        </div>
      </div>
    </div>
  );
}

function OrgDetailSettings(props: {
  org: Organization;
  onChange: (updated: Organization) => void;
  admin?: boolean;
}): JSX.Element | null {
  const { org } = props;

  const {
    call: updateSettings,
    state: {
      state: { isRunning },
      error,
    },
    reset,
  } = useLiveAsyncCall(async (req: DtoUpdateOrgSettingsRequest) => {
    const resp = await apiService.organization.updateOrgSettings(org.id, req);
    return resp.data.organization;
  });

  const handleChange = useLiveCallback(
    async (prop: keyof DtoUpdateOrgSettingsRequest, val: boolean) => {
      reset();
      const updatedOrg = await updateSettings({
        [prop]: val,
      });
      if (!updatedOrg) return;
      props.onChange(updatedOrg);
    }
  );

  return (
    <div className='text-white mb-5'>
      <div className='text-base font-bold'>Schedule Events</div>
      <div className='mt-2 flex flex-col gap-2'>
        <div className='w-100 flex items-center justify-between'>
          <div className='text-sms font-normal'>
            Only Admins can schedule Live events
            {error && <div className='text-red-002'>{err2s(error)}</div>}
          </div>
          {isRunning ? (
            <Loading text='' imgClassName='w-6 h-6' />
          ) : (
            <div className='flex items-center justify-center gap-1'>
              <SwitcherControlled
                name='org-schedule-live-events-perm-toggle'
                className=''
                checked={!!org.settings?.nonAdminScheduleLiveEventsDisabled}
                onChange={(v) =>
                  handleChange('nonAdminScheduleLiveEventsDisabled', v)
                }
                disabled={isRunning}
              />
            </div>
          )}
        </div>

        {props.admin && (
          <div className='w-100 flex items-center justify-between'>
            <div className='text-sms font-normal'>
              Allow live events during trial
              {error && <div className='text-red-002'>{err2s(error)}</div>}
            </div>
            {isRunning ? (
              <Loading text='' imgClassName='w-6 h-6' />
            ) : (
              <div className='flex items-center justify-center gap-1'>
                <SwitcherControlled
                  name='org-schedule-live-events-during-trial-perm-toggle'
                  className=''
                  checked={!!org.settings?.allowLiveEventsDuringTrial}
                  onChange={(v) =>
                    handleChange('allowLiveEventsDuringTrial', v)
                  }
                  disabled={isRunning}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * General organization settings for organization owners and organization admins.
 */
export const OrganizationSettings = (): JSX.Element => {
  const user = useUser();
  const { updateUser } = useUserContext();
  const { org, updateOrg } = useOrgContext();
  const { connection } = useOrgConnection({
    orgId: org.id,
  });
  const [editingField, setEditingField] = useState<FieldKind | null>(null);

  const handleChange = (organization: Organization) => {
    if (user.organizer && user.organizer.organization) {
      updateUser({
        organizer: {
          ...user.organizer,
          organization: organization,
        },
      });
      updateOrg(organization);
    }
    setEditingField(null);
  };

  return (
    <div className='w-full h-full text-white'>
      <h1 className='text-white text-2xl font-medium flex items-center gap-2.5 mb-7.5'>
        <SettingIcon className='h-6 w-6 fill-current' /> {org.name} Settings
      </h1>

      <OrgDetailSettings org={org} onChange={handleChange} />

      <LogoEditor
        organization={org}
        connection={connection}
        onChange={handleChange}
      />

      <BrandColorField
        organization={org}
        onChange={handleChange}
        editing={editingField === 'brandColor'}
        onEdit={() => setEditingField('brandColor')}
        onCancelEdit={() => setEditingField(null)}
      />
    </div>
  );
};

type FieldKind =
  | 'name'
  | 'size'
  | 'subscriptionType'
  | 'subscriptionStatus'
  | 'subscriptionId'
  | 'canTrial'
  | 'onboardingCallScheduled'
  | 'venueConnectivityTest'
  | 'cancelOfferDisabled'
  | 'brandColor';

/**
 * General organization settings for admins.
 */
export const OrganizationDetailsGeneral = (): JSX.Element | null => {
  const navigate = useNavigate();

  const { org, updateOrg } = useOrgContext();
  const {
    connection,
    isLoading: isConnectionLoading,
    error: loadConnectionError,
    refresh: refreshConnection,
  } = useOrgConnection({
    orgId: org.id,
  });
  const [editingField, setEditingField] = useState<FieldKind | null>(null);

  if (isConnectionLoading) {
    return <Loading containerClassName='mt-30' />;
  }
  if (loadConnectionError) {
    return (
      <div className='mt-30 text-red-002 text-center'>
        {err2s(loadConnectionError)}
      </div>
    );
  }

  const handleUpdated = (updates: Partial<Organization>) => {
    updateOrg(updates);
    setEditingField(null);
  };

  const handleDeleted = () => {
    navigate('/admin/organizations', { replace: false });
  };

  return (
    <div className='w-full h-full pl-8 pt-10 pr-12.5'>
      <header className='flex items-center justify-between'>
        <div className='flex items-center'>
          <SettingIcon className='w-5 h-5 fill-current' />
          <div className='ml-2 font-medium text-2xl'>General</div>
        </div>

        <HostForOrgButton org={org} />
      </header>

      <main className='flex flex-col items-start gap-5 mt-12.5'>
        <DisplayNameField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'name'}
          onEdit={() => setEditingField('name')}
          onCancelEdit={() => setEditingField(null)}
        />

        <div className='w-full'>
          <SizeField
            organization={org}
            onChange={handleUpdated}
            editing={editingField === 'size'}
            onEdit={() => setEditingField('size')}
            onCancelEdit={() => setEditingField(null)}
          />
          <SeatOverageNotificationField
            organization={org}
            onChange={handleUpdated}
          />
        </div>

        <SubscriptionProductField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'subscriptionType'}
          onEdit={() => setEditingField('subscriptionType')}
          onCancelEdit={() => setEditingField(null)}
        />

        <SubscriptionStatusField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'subscriptionStatus'}
          onEdit={() => setEditingField('subscriptionStatus')}
          onCancelEdit={() => setEditingField(null)}
        />

        <SubscriptionIdField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'subscriptionId'}
          onEdit={() => setEditingField('subscriptionId')}
          onCancelEdit={() => setEditingField(null)}
        />

        <CanTrialField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'canTrial'}
          onEdit={() => setEditingField('canTrial')}
          onCancelEdit={() => setEditingField(null)}
        />

        <CancelOfferField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'cancelOfferDisabled'}
          onEdit={() => setEditingField('cancelOfferDisabled')}
          onCancelEdit={() => setEditingField(null)}
        />

        <OnboardingCallScheduledField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'onboardingCallScheduled'}
          onEdit={() => setEditingField('onboardingCallScheduled')}
          onCancelEdit={() => setEditingField(null)}
        />

        <VenueConnectivityTestField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'venueConnectivityTest'}
          onEdit={() => setEditingField('venueConnectivityTest')}
          onCancelEdit={() => setEditingField(null)}
        />

        <PausePromotionsToggle org={org} onChange={handleUpdated} />

        <OrgDetailSettings org={org} onChange={handleUpdated} admin />

        <LogoEditor
          organization={org}
          connection={connection}
          onChange={handleUpdated}
        />

        <BrandColorField
          organization={org}
          onChange={handleUpdated}
          editing={editingField === 'brandColor'}
          onEdit={() => setEditingField('brandColor')}
          onCancelEdit={() => setEditingField(null)}
        />

        <section className='mt-3 flex flex-col items-start gap-4'>
          {connection && OrgUtils.IsSlackActive(connection) && (
            <AsSourceOfTruthToggle
              connection={connection}
              onChange={refreshConnection}
            />
          )}

          <UsesSlackToggle
            organization={org}
            connection={connection || null}
            onChange={(usesSlack) =>
              handleUpdated({
                usesSlack,
              })
            }
          />

          <DeleteButton organization={org} onDelete={handleDeleted} />
        </section>
      </main>
    </div>
  );
};
